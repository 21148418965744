import {Col, Row, Table, Typography} from "antd";
import {ColumnsType} from "antd/lib/table";
import {getColumnDateProps, getStandardFilter} from "../../tables/negotiationsTables/columnsUtils";
import dayjs from "dayjs";
import Title from "antd/lib/typography/Title";
import InfoModal from "../../modals/InfoModal";
import React, {useCallback, useEffect, useState} from "react";
import {cloneDeep, isNil} from "lodash";
import {useLazyGetCoverageConfirmationReportsQuery} from "../../../redux/api/coverageConfirmationApiSlice";
import {CoverageConfirmationReport, CoverageConfirmationReportFilters, PassState} from "../../../types/negotiations";
import {ToNegotiationButton} from "./BelatedEmittedBatchReportCard";
import Tag from "antd/es/tag";
import {getContrastYIQ} from "../../../utils/formatters";
import {useSelector} from "react-redux";
import {selectOptions} from "../../../redux/features/optionsSlice";
import ContractorFilter from "../../tables/negotiationsTables/ContractorFilter";
import FilterBySearch from "../../tables/negotiationsTables/generalTable/FilterBySearch";

const Info = () => (
    <>
        La tabella mostra l'esito delle operazioni di caricamento dei data model di Pass.
    </>
)
const PAGE_SIZE = 7;

export default function UploadPassReportCard({className}: { className: string }) {
    const [getData, {data}] = useLazyGetCoverageConfirmationReportsQuery()
    const [filters, setFilters] = useState<Partial<CoverageConfirmationReportFilters>>({})
    const [sorters, setSorters] = useState<string>("")
    const options = useSelector(selectOptions)

    const getDataApi = useCallback((params: CoverageConfirmationReportFilters) => {
        getData(params)
    }, [getData])

    useEffect(() => {
        getDataApi({page: 1, page_size: PAGE_SIZE})
    }, [getDataApi])

    const tableColumns: ColumnsType<CoverageConfirmationReport> = [
        {
        title: 'Data di creazione',
        dataIndex: 'date_creation',
        key: 'date_creation',
        ...getColumnDateProps('date_creation'),
        render: (text: string) => {
            return text ? dayjs(text).format('DD-MM-YYYY') : ""
        },
        width: '16%'
    },
    {
        title: 'Esito',
        dataIndex: 'state',
        key: 'state',
        width: '16%',
        ...getStandardFilter([
            {text: 'Errore', value: PassState.ERROR},
            {text: 'Completato', value: PassState.OK},
        ]),
        render: (value, record) => {
            switch (record.state) {
                case PassState.ERROR:
                    return <span style={{color: 'red'}}>Errore</span>
                case PassState.OK:
                    return "Completato"
            }
        }
    },
    {
        title: "Contraente",
        dataIndex: "contractor",
        key: "contractor",
        width: '20%',
        filterDropdown: (props) => {
            return ContractorFilter(props)
        }
    },
    {
        title: "LOB",
        dataIndex: "lob",
        key: "lob",
        width: '16%',
        render: (lob) => {
                            const foundLob = options.lobs.find(el => el.name === lob)
                            if (foundLob) {
                                return <Tag
                                    style={{
                                        borderRadius: '100px',
                                        width: '7rem',
                                        height: '1.25rem',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        padding: '0 0.5rem',
                                        color: getContrastYIQ(foundLob.color)
                                    }}
                                    color={foundLob.color}
                                >
                                    {foundLob.name}
                                </Tag>
                            }
                        },
        ...getStandardFilter(options.lobs.map(el => ({text: el.name, value: el.uuid})))
    },
    {
        title: "Numero proposta",
        dataIndex: "proposal_number",
        key: "proposal_number",
        width: '16%',
        filterDropdown: (props) => {
            return <FilterBySearch props={props} placeholder={'Filtra per numero proposta...'}/>
        }
    },
    {
        dataIndex: 'negotiation',
        key: 'negotiation',
        width: '10%',
        render: (negotiation, record) => record.negotiation ? <ToNegotiationButton uuid={record.negotiation}/> : ""
    },
    Table.EXPAND_COLUMN
    ]

    return <div className={className}>
        <Row style={{marginTop: '1rem', marginBottom: '1rem'}} justify={"space-between"} align={'middle'}>
            <Col xs={{offset: 1, flex: 1}} sm={{offset: 0}}>
                <div style={{display: "flex", flexWrap: 'nowrap'}}>
                    <Title level={3}>
                        Esiti caricamento data model di Pass
                    </Title>
                    <InfoModal
                        title={"Esiti caricamento data model di Pass"}
                        description={<Info/>}/>
                </div>
            </Col>
        </Row>
        <Table
            <CoverageConfirmationReport>
            columns={tableColumns}
            rowKey={'uuid'}
            scroll={{x: true, scrollToFirstRowOnChange: true}}
            onChange={(pagination, filters, sorters, extra) => {
                const updatedCols = cloneDeep(tableColumns)
                const page = pagination.current || 1;
                let requestFilters: Partial<CoverageConfirmationReportFilters> = {}
                let requestSorter: string[] = []
                for (const [key, value] of Object.entries(filters)) {
                    if (!isNil(value)) {
                        switch (key) {
                            case 'date_creation':
                                requestFilters.date_creation_from = value[0] as string
                                requestFilters.date_creation_to = value[1] as string
                                break;
                            case 'state':
                                requestFilters['state'] = value.join(',')
                                break;
                            case 'contractor':
                                requestFilters[key] = (value as any).map((item: any) => item.value).join(',')
                                break
                            case 'lob':
                                requestFilters[key] = value.join(',')
                                break
                            case 'proposal_number':
                                requestFilters[key] = value.join(',')
                        }
                    }
                }

                if (Array.isArray(sorters)) {
                    sorters.reverse().forEach((sorter) => {
                        if (!isNil(sorter.order)) {
                            let colToUpdate = updatedCols.find(col => col.key === sorter.columnKey)
                            if (colToUpdate) {
                                if (sorter.order === 'ascend') {
                                    requestSorter.push(sorter.field as string)
                                } else {
                                    requestSorter.push('-' + sorter.field)
                                }
                            }
                        }


                    })
                } else {
                    if (sorters.order !== undefined) {
                        if (sorters.order === 'ascend') {
                            requestSorter.push(sorters.field as string)
                        } else {
                            requestSorter.push('-' + sorters.field)
                        }
                    }
                }
                setFilters(requestFilters)
                setSorters(requestSorter.join(','))
                getData({
                    page,
                    page_size: PAGE_SIZE,
                    ordering: requestSorter.join(','), ...requestFilters
                })
            }}
            dataSource={data?.results}
            pagination={{total: data?.count, pageSize: PAGE_SIZE, simple: true}}
            expandable={{
                expandedRowRender: (record) => <>
                    <Typography.Text>Descrizione: </Typography.Text>
                    <Typography.Text italic style={{fontWeight: 'normal'}}>{record.data.error ?? ""}</Typography.Text>
                </>,
                rowExpandable: (record) => record.state === PassState.ERROR,
            }}
        />
    </div>
}