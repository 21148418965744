import {Dayjs} from "dayjs";
import {LobModel} from "types/options";
import {BrokerModel} from "../broker";
import {ChecklistType} from "../../redux/api/checklistApiSlice";
import {ReinsuranceType} from "types/reinsuranceType";
import {ContractorModel} from "../contractors";
import {CoverageConfirmationStates} from "../../pages/negotiations/CoverageConfirmation";

export type AtecoModel = {
    uuid: string,
    nace: string,
    full_description: string
}

export type GetAtecosModel = {
    count: number,
    next: string | null,
    previous: string | null,
    results: AtecoModel[]
}

export type CreateNegotiationTransitionRequest = {
    state_to: number,
    reason_text?: string,
    notes?: string,
    responsible_user?: string
}

export type NegotiationTransitionModel = CreateNegotiationTransitionRequest & {
    uuid: string
    negotiation: string,
    state_from: number,
    date_creation: Date,
    available_states: AvailableStateTransitionModel[],
    revert_state_transition: AvailableStateTransitionModel[],
    new_negotiation_uuid: string,
    is_portfolio_managed: boolean
}

export type NegotiationTransitionHistory = {
    uuid: string,
    user_creation: string | null,
    state_from: number | null,
    state_to: number | null,
    responsible_user: string | null,
    reason_text: string | null,
    notes: string | null,
    negotiation: string | null,
    date_creation: Date | null,
}


export type MountBelatedEmittedRequest = {
    mounting_date: Date
}

export type UnmountBelatedEmittedForm = {
    installment_premium_actual: number,
    installment_premium_residual: number,
    installment_commission_residual: number,
    unmounting_date: Dayjs,
    certificate_number: string
}

export interface UnmountBelatedEmittedRequest extends Omit<UnmountBelatedEmittedForm, 'unmounting_date'> {
    unmounting_date: Date,
}

export type UpdateBelatedEmittedForm = {
    installment_effective_date: Dayjs
    installment_expiring_date: Dayjs
    installment_premium: number
    installment_commission: number
    certificate_number: string,
    installment_premium_actual: number,
    installment_premium_residual: number,
    installment_commission_residual: number,
    unmounting_date: Dayjs,
    installment_class: 'STD' | 'PRT'
}

export type BelatedEmittedModel = {
    uuid: string
    negotiation: string,
    certificate_number: string | null,
    installment_num: number,
    installment_effective_date: Date,
    installment_expiring_date: Date,
    installment_premium: number
    installment_commission: number
    installment_premium_actual: number | null
    installment_premium_residual: number | null
    installment_commission_residual: number | null
    installment_class: 'STD' | 'PRT'
    is_mounted: boolean | null
    mounting_date: Date | null
    is_unmounted: boolean | null
    is_unmounted_all: boolean | null
    unmounting_date: Date | null
    unmounting_user: string | null
    is_batch: boolean | null
}

export type BelatedEmittedREVISITED = {
    uuid: string
    negotiation: string,
    certificate_number: string | null,
    installment_num: number,
    installment_effective_date: string,
    installment_expiring_date: string,
    installment_premium: number
    installment_commission: number
    installment_premium_actual: number | null
    installment_premium_residual: number | null
    installment_commission_residual: number | null
    installment_class: 'STD' | 'PRT'
    is_mounted: boolean | null
    mounting_date: string | null
    is_unmounted: boolean | null
    is_unmounted_all: boolean | null
    unmounting_date: string | null
    unmounting_user: string | null
    is_batch: boolean | null
}

export type CreateNewNegotiationFormModel = {
    lob: string,
    contractor: string,
    delegated_insurer: string,
    is_isia: boolean,
    policy_effective_date: Dayjs,
    reception_date: Dayjs,
    broker: string
    uw_year?: number,
    has_prorata?: boolean,
}

export interface CreateNewNegotiationRequest extends Omit<CreateNewNegotiationFormModel, 'policy_effective_date' | 'contractor' | 'reception_date'> {
    policy_effective_date: Date,
    reception_date: Date,
    contractor: string
}

export type DuplicateNegotiation = {
    uuid: string,
    state: number,
    lob: string,
    broker: string,
    contractor: string,
    uw_year: string,
    policy_effective_date: Date,
    policy_expiring_date: Date
}

export type NegotiationFormModel =
    Omit<NegotiationModel, 'url' | 'state' | 'lob' | 'underwriter' | 'broker' | 'isia_sale' |
        'reception_date' | 'policy_effective_date' | 'effect_hour' | 'policy_expiring_date' | 'uw_year' | 'prorata_uw_year' | 'prorata_effective_date' |
        'contractor_name_raw' | 'attorney_from' | 'contractor' | 'fund' | 'reinsurer_broker' | 'delegated_insurer' |
        'days_to_dead_line' | 'days_to_expiration' | 'be_titles_mismatch'
    >
    & {
    lob: string,
    underwriter: string | null,
    broker: string | null,
    isia_sale: string | null,
    reception_date: Dayjs | null | undefined
    policy_effective_date: Dayjs | null | undefined,
    effect_hour: [string, string] | undefined,
    policy_expiring_date: Dayjs | null | undefined,
    prorata_effective_date: Dayjs | null | undefined,
    prorata_expiring_date: Dayjs | null | undefined,
    uw_year: Dayjs | null | undefined,
    prorata_uw_year: Dayjs | null | undefined,
    prorata_total: number | null,
    contractor: string | null, //only uuid, data is managed in ContractorDataFromModel
    fund: string | null,
    delegated_insurer: string | null,
    attorney_from: string | null,
    reinsurer_broker: string | null,
}

export type UpdateNegotiationRequest = Partial<Omit<NegotiationFormModel, 'reception_date' | 'policy_effective_date' | 'policy_expiring_date' | 'prorata_effective_date' | 'prorata_expiring_date' | 'prorata_uw_year' | 'uw_year' | 'contractor' | 'fund' | 'delegated_insurer' | 'attorney_from'> & {
    reception_date: Date | null,
    policy_effective_date: Date | null,
    policy_expiring_date: Date | null,
    prorata_effective_date: Date | null,
    uw_year: number | null,
    prorata_uw_year: number | null,
    contractor: string | null,
    fund: string | null,
    delegated_insurer: string | null,
    attorney_from: string | null
}>

export type FieldsCalculated =
    'fee_total_perc'
    | 'isa_quoted_premium_share'
    | 'isa_fee'
    | 'saved_premium'
    | 'reference_premium'
    | 'regulation_premium'
    | 'policy_premium'
    | 'net_premium_to_reinsurers'
    | 'policy_insured_sum'
    | 'policy_insured_sum_net_reins';
export type CalculatedField = {
    [key in FieldsCalculated]: boolean;
};

export type AvailableStateTransitionModel = {
    state: number,
    isReasonMandatory: { value: string, text: string }[],
    isNotesMandatory: boolean
}

export type NegotiationModelKey = keyof Omit<NegotiationModel, 'raw_data'>;

export type NegotiationModel = {
    available_state_transitions: AvailableStateTransitionModel[],
    revert_state_transition: AvailableStateTransitionModel[],
    url: string,
    uuid: string,
    state: number,
    checklist_answers: { uuid: string, checklist_type: ChecklistType }[],
    lob: LobModel,
    underwriter: NegotiationUserModel | null,
    is_isia: boolean | null,
    isia_type: string | null,
    isia_sale: NegotiationUserModel | null,
    broker: BrokerModel | null,
    fee_total_perc: number | null,
    fee_broker_perc: number | null,
    fee_agency_perc: number | null,
    fee_bank_perc: number | null,
    broker_account_executive: string | null,
    broker_account_executive_email: string | null, // ?
    broker_branch: string | null,
    is_lps: boolean | null,
    framework_agreement: string | null,
    reception_date: string | null,
    policy_effective_date: string | null,
    policy_effective_date_confirmed: boolean | null, //?
    effect_hour: string, // format -> XX:XX
    policy_expiring_date: string | null,
    uw_year: number | null,
    quoted_premium: number | null,
    isa_share_perc: number | null,
    isa_quoted_premium_share: number | null,
    target_premium: number | null,
    is_from_rore: boolean | null,
    is_imported: boolean | null,
    has_prorata: boolean,
    prorata_effective_date: string | null,
    prorata_uw_year: number | null,
    prorata_isa_share: number | null,
    can_have_coverage_confirmation: boolean,

    //additional insureds
    policy_with_certificates: boolean,

    //contractor
    contractor_name_raw: string | null,
    contractor: ContractorModel | null,
    has_fund: boolean,
    fund: ContractorModel | null,

    //reinsurance
    reinsurance_type: ReinsuranceType | null,
    reinsurer_broker: InsurerModel | null,
    reinsurer_broker_fee_perc: number | null,
    reinsured_share_perc: number | null,
    isa_fee: number | null,
    saved_premium: number | null,
    attachment_point: number | null,
    reinsurance_notes: string,
    reinsurers_list: string[],
    net_premium_to_reinsurers: number | null,
    ppw_days: number | null,

    attorney_from: NegotiationUserModel | null, //

    //negotiationData
    delegated_insurer: {
        uuid: string,
        name: string
    } | null,
    policy_product_type: string | null,
    is_auto_renewal: boolean | null,
    cancellation_terms_type: string | null,
    cancellation_terms_days: number | null,
    tiv: number | null | undefined,
    insured_sum: number | null,
    is_new_business: boolean,
    reference_premium: number | null,
    ancillary_charges: number | null,
    regulation_premium: number | null,
    refreshment_commissions: number | null,
    raw_data: { [key in NegotiationModelKey]: string[] } | null,
    is_renewable: boolean,

    //portfolio
    is_portfolio_managed: boolean | null,
    product_type: string | null,
    branches: { [key: (number | string)]: number } | null,
    portfolio_user: NegotiationUserModel | null,
    is_policy_cancelled: boolean,
    //belated_emitted
    be_titles_mismatch: boolean,
    has_proposal_number: boolean,
    proposal_number: string | null,

    //policiesAndIssues
    is_framework_agreement: boolean,
    policy_number: string | null,
    installment_type: string | null,
    installments_number: number | null,
    policy_premium: number | null,
    policy_insured_sum: number | null,
    policy_insured_sum_net_reins: number | null,
    policy_notes: string | null,
    portfolio_waiting_reason: string | null

    days_to_dead_line: number | null,
    days_to_expiration: number | null,
    has_regulation_premium: boolean,

    // fields that are calculated
    is_calculated: CalculatedField

    //link to submission in Riskapp
    submission_link: string | null,

    //link to other negotiations
    descendant_uuid: string | null,
    ancestor_uuid: string | null,
    master_agreement_negotiation: string | null,

    //coverage confirmation
    coverageconfirmation: string | null,
    can_manage_certificates: boolean | null,
}

export type NegotiationLobUsers = {
    url: string,
    uuid: string,
    name: string,
    underwriters: NegotiationUserModel[],
    managers: NegotiationUserModel[],
    color: string
}
export type NegotiationUserModel = {
    uuid: string,
    user_full_name: string,
    usertypes: {
        name: string,
        code: string
    }[]

}

export type NegotiationReinsurerModel = {
    uuid: string,
    reinsurer: {
        uuid: string
        name: string
    },
    fee: number

}

export type NegotiationReinsurerFormModel = {
    reinsurer: string,
    fee: number | undefined
}

export type NegotiationReinsurerFormModelPut = {
    reinsurer: string,
    fee: number | null
}

export type InsurerModel = {
    url: string,
    uuid: string,
    name: string,
}

export type BelatedReports = {
    uuid: string,
    reference_date: Dayjs,
    base_report_type?: string
}

export type BelatedReportsResponse = {
    count: number,
    next: string | null,
    previous: string | null,
    results: BelatedReports[]
}

export type BelatedReportsOptions = {
    value: string,
    text: string
}

export type CoverageConfirmation = {
    negotiation: string,
    state_label: string,
    uuid: string,
    data: any,
    raw_data: null,
    visual_data: null,
    state: CoverageConfirmationStates,
    datetime_send: string,
}

export enum PassState {
    'ERROR' = 'KO',
    'OK' = "OK"
}

export type CoverageConfirmationReport = {
    "coverage_confirmation": string,
    "data": {
        "error": string | null,
    },
    "date_creation": string,
    "negotiation": string | null,
    "state": PassState,
    "user_creation": string,
    "uuid": string,
}

export type CoverageConfirmationReportFilters = {
    page: number,
    page_size: number,
    date_creation_from?: string,
    date_creation_to?: string,
    lob?: string,
    contractor?: string,
    proposal_number?: string,
    state?: string,
    ordering?: string
}

export type CoverageConfirmationReportsResponse = {
    count: number,
    next: string | null,
    previous: string | null,
    results: CoverageConfirmationReport[]
}